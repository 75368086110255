import { Injectable } from '@angular/core';
import { ThemeColorService, TypographyService } from '@shared/feature/theme-service';
import { FontSelection, getUnit, ThemeMainColorType } from '@shared/feature/themes';
import { Options, setOptions } from 'highcharts';

@Injectable({
  providedIn: 'root',
})
export class HighchartsThemeService {
  readonly colors: string[];
  private isApplied = false;
  constructor(
    private typographyService: TypographyService,
    private themeColorService: ThemeColorService,
  ) {
    this.colors = [
      this.themeColorService.getColorHexValue(ThemeMainColorType.primaryColor, '900'),
      this.themeColorService.getColorHexValue(ThemeMainColorType.primaryColor, '500'),
      this.themeColorService.getColorHexValue(ThemeMainColorType.primaryColor, '300'),
      this.themeColorService.getColorHexValue(ThemeMainColorType.primaryColor, '100'),
    ];
  }

  applyThemeOptions() {
    if (!this.isApplied) {
      const options = this.defaultTheme(this.typographyService);
      setOptions(options);
      // this._options = options;
      this.isApplied = true;
    }
  }

  private defaultTheme(typographyService: TypographyService): Options {
    return {
      colors: this.colors,
      title: {
        style: this.getTitleStyle(typographyService.typography),
      },
      subtitle: {
        style: this.getSubTitleStyle(typographyService.typography),
      },
      xAxis: {
        title: {
          style: this.getAxisStyle(typographyService.typography),
        },
        labels: {
          style: this.getAxisStyle(typographyService.typography),
        },
      },
      yAxis: {
        title: {
          style: this.getAxisStyle(typographyService.typography),
        },
        labels: {
          style: this.getAxisStyle(typographyService.typography),
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
        itemStyle: this.getLegendStyle(typographyService.typography),
        itemHoverStyle: {
          color: this.themeColorService.getColorHexValue(ThemeMainColorType.primaryColor, '500'),
        },
      },
      annotations: [
        {
          labelOptions: {
            style: this.getAnnotationStyle(typographyService.typography),
          },
        },
      ],
    };
  }

  private getSubTitleStyle(typography: { [key: string]: FontSelection }) {
    let style = {
      color: 'rgba(0,0,0,0.6)',
    };
    style = { ...style, ...this.getStyle(typography, 'subheading2') };
    return style;
  }

  private getLegendStyle(typography: { [key: string]: FontSelection }) {
    let style = {
      color: 'rgba(0,0,0,0.87)',
    };
    style = { ...style, ...this.getStyle(typography, 'body1') };
    return style;
  }

  private getAnnotationStyle(typography: { [key: string]: FontSelection }) {
    let style = {
      color: 'rgba(255,255,255)',
    };
    style = { ...style, ...this.getStyle(typography, 'caption') };
    return style;
  }

  private getAxisStyle(typography: { [key: string]: FontSelection }) {
    let style = {
      color: 'rgb(0,0,0)',
    };
    style = { ...style, ...this.getStyle(typography, 'body1') };
    return style;
  }

  private getTitleStyle(typography: { [key: string]: FontSelection }) {
    let style = {
      color: 'rgba(0,0,0,0.87)',
    };

    style = { ...style, ...this.getStyle(typography, 'title') };

    return style;
  }

  private getStyle(typography: { [key: string]: FontSelection }, level: string): { [key: string]: FontSelection } {
    const fontSelection = typography[level];
    const style = this.getFontFamily(typography);
    Object.keys(fontSelection).forEach((key: string) => {
      // const variableName = ThemingService.convertCamelCaseToKebabCase(key);
      const typographyConfigKey = key as keyof FontSelection;
      let value = fontSelection[typographyConfigKey] as unknown as string;
      const unit = getUnit(typographyConfigKey);
      if (unit) {
        value = value + unit;
      }
      // Exclude lineHeight. Seems to have an convert error for svg dy property
      if (key !== 'lineHeight') {
        style[key] = value as unknown as FontSelection;
      }
    });
    return style;
  }

  private getFontFamily(typography: { [key: string]: FontSelection }): { [key: string]: FontSelection } {
    return {
      'font-family': typography['fontFamily'],
    };
  }
}
